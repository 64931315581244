export function getQueries(){
  const hashSplit = window.location.hash.split('?')
  if (hashSplit.length <= 1){
    return {}
  }
  return JSON.parse(
    '{"' +
    decodeURI(
      hashSplit.pop().replace(/&$/, "")
      .replace(/&/g, "\",\"")
      .replace(/=/g,"\":\"")
    ) +
    '"}'
  )
}
export function setQueries(queries){
  const old_queries = getQueries()
  const new_queries = {
    ...old_queries,
    ...queries
  }
  return Object.keys(new_queries).reduce(
    (q,k,i,a) => (q+(typeof new_queries[k] === 'undefined' ? '' : k+'='+encodeURI(new_queries[k])+(i+1<a.length?'&':''))),
    '?'
  )
}

export function b64EncodeUnicode(str) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode('0x' + p1)));
}

export function isDark(color) {
    // Variables for red, green, blue values
    var r, g, b, hsp;

    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(
    color.length < 5 && /./g, '$&$&'))

    r = color >> 16
    g = (color >> 8) & 255
    b = color & 255

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
    )

    // Using the HSP value, determine whether the color is light or dark
    if (hsp>137.5) { return false }
    else { return true }
}


export function handleLogoUrl(integration_name) {
    let odoo = window.ODOO.url + (window.ODOO.port?':'+window.ODOO.port:'')
    let notShippingIntegrations = {
      'emag': 'emag',
      'erli': 'erli',
      'allegro': 'allegro',
    }
    let shippingIntegrationsToRemap = {
      'olza_logistic': 'olza',
      'orlen_paczka': 'orlen',
      'packeta': 'packetery',
    }
    if (integration_name) {
      integration_name = integration_name.toLowerCase().replace(/ /g, '_')
      for (const [key, value] of Object.entries(notShippingIntegrations)) {
        if (integration_name.includes(key)) {
          return `${odoo}/ideaerp_${value}/static/img/logo.png`
        }
      }
      for (const [key, value] of Object.entries(shippingIntegrationsToRemap)) {
        if (integration_name.includes(key)) {
          integration_name = value
        }
      }
      return `${odoo}/ideaerp_shipment_${integration_name}/static/img/logo.png`
    }
    return false
}
