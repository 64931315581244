import React from 'react'
import intl from 'react-intl-universal'
import Popup from 'react-popup'
import { connect } from 'react-redux'
import { selectShipping, getShipping, saveShipping, clearShippingData } from '../actions/ShippingActions'

const extraFields = ['currency_id', 'receiver_country', 'sizes', 'types', 'carriers', 'shops', 'countries']

const mapStateToProps = state => ({
  shipping: state.shipping.shipping,
  shipping_data: state.shipping.shipping_data,
  order_id: state.router.location.query.order ? parseInt(state.router.location.query.order) : false,
  order: state.pack.order,
})

const mapDispatchToProps = dispatch => ({
  getShippingAction: (id, order_id) => dispatch(getShipping(id, order_id)),
  selectShippingAction: (id) => dispatch(selectShipping(id)),
  saveShippingAction: (id, data, order_id) => dispatch(saveShipping(id, data, order_id)),
  clearShippingData: () => dispatch(clearShippingData())
})

const addTmpIds = (parcels) => {
  return parcels.map((p, i) => {
    p.tmpid = `#${i+1}`
    return p
  })
}

class ShippingComponent extends React.Component {
  constructor(props) {
    super(props)
    const { shipping } = props
    this.state = {
      editMode: shipping === 0,
      newMode: shipping === 0,
      extraRows: [],
      deleteRows: [],
      selectedCarrier: false,
      saving: false
    }
  }

  componentDidMount() {
    const { shipping_id, getShippingAction, order_id } = this.props
    if(shipping_id !== false) getShippingAction(parseInt(shipping_id), order_id)
  }

  componentDidUpdate() {
    //domyślne paczki przerzucamy jako extraRows czyli nowo utworzone
    const { shipping, shipping_data } = this.props
    const { extraRows } = this.state
    if(shipping === 0 &&
       shipping_data &&
       shipping_data.shipping_parcels_ids &&
       shipping_data.shipping_parcels_ids.length > 0 &&
       extraRows.length === 0) {
      this.setState({
        extraRows: addTmpIds(shipping_data.shipping_parcels_ids)
      })
    }
  }

  componentWillUnmount() {
    const { saving } = this.state
    const { clearShippingData } = this.props
    if(!saving){
      clearShippingData()
    }
  }

  deselectShipping = (event) => {
    const {selectShippingAction, parent} = this.props
    Popup.close()
    parent.shippingList()
    selectShippingAction(false)
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { shipping_data, saveShippingAction, order_id } = this.props
    const { extraRows, deleteRows, newMode } = this.state
    let inputs = Array.from(document.querySelectorAll('.shipping input, .shipping select'))
    let diff = {}
    inputs.map(input => {
      let val = input.value
      if (input.tagName === 'SELECT'){
        val = parseInt(val)
      }
      if(input.dataset.parcel){
        //paczki
        let parcel_id = parseInt(input.name.replace( /^\D+/g, ''))
        let parcel_tmpid = input.dataset.tmpid
        let field = input.name.split('_').splice(0, input.name.split('_').length-1).join('_')

        let parcel_found = shipping_data.shipping_parcels_ids.find(p => (p.id === parcel_id))
        if(!parcel_found){
          parcel_found = extraRows.find(p => (p.tmpid === parcel_tmpid))
        }
        if(!parcel_found){
          Popup.alert(intl.get('Wystąpił nieoczekiwany błąd. Odśwież stronę i spróbuj ponownie'))
        }
        if(typeof parcel_found.id === 'undefined' || (parcel_found[field] != val) || newMode){
          if(typeof diff.shipping_parcels_ids === 'undefined'){
            diff.shipping_parcels_ids = []
          }
          let parcel_diff = diff.shipping_parcels_ids.find(p => {
            return (p.id === parcel_id) || (p.tmpid === parcel_tmpid)
          })
          if(!parcel_diff){
            if(parcel_id){
              parcel_diff = {id: parcel_id}
            } else {
              parcel_diff = {tmpid: parcel_tmpid}
            }
            diff.shipping_parcels_ids.push(parcel_diff)
          }
          parcel_diff[field] = val
        }
        return true
      }
      //zwykle pola
      if(input.type === 'checkbox'){
        val = !!input.checked
      }
      if((val != shipping_data[input.name]) || newMode){
        diff[input.name] = val
      }
      return true
    })
    if(newMode){
      diff['id'] = 0
      //pola z get_shipping_data, które nie mają swoich inputów
      Object.keys(shipping_data).filter((key) => {
        return (Object.keys(diff).indexOf(key) === -1) && (extraFields.indexOf(key) === -1)
      }).map(key => {
        diff[key] = shipping_data[key]
      })
    }
    if(deleteRows.length > 0){
      diff['parcel_delete'] = deleteRows
    }
    if(Object.keys(diff).length === 0){
      Popup.alert(intl.get('Nie znaleziono zmian'))
    }
    const parcelSizesInput = document.getElementById('parcelSizes');
    if (shipping_data.shipping_parcels_ids && Array.isArray(shipping_data.shipping_parcels_ids) && shipping_data.shipping_parcels_ids.length > 0) {
      const latestParcel = shipping_data.shipping_parcels_ids[shipping_data.shipping_parcels_ids.length - 1];
      const parcelSize = latestParcel.size[1];
      if (parcelSizesInput) {
        parcelSizesInput.value = parcelSize;
      }
    } else {
      if (parcelSizesInput) {
        parcelSizesInput.value = '';
      }
    }
    this.setState({saving: true})
    saveShippingAction(shipping_data.id, diff, order_id)
  }

  toggleEditMode = () => {
    const { editMode } = this.state
    this.setState({
      editMode: !editMode,
      extraRows: this.clearEmpty(),
      deleteRows: []
    })
  }

  clearEmpty(){
    const { extraRows } = this.state
    return extraRows.filter(p => (
      Array.from(document.querySelectorAll('[data-tmpid="'+p.tmpid+'"]')).some(i => (i.value !== ''))
    ))
  }

  newParcelRow = () => {
    const { shipping_data } = this.props
    const { newMode } = this.state
    const extraRows = this.clearEmpty()
    
    const defaultValues = newMode ? 
      (extraRows && extraRows.length > 0) ? extraRows[0] : undefined 
      : 
      (shipping_data.shipping_parcels_ids || [])[0]
    this.setState({
      extraRows: [
        ...extraRows,
        {
          ...(defaultValues ? {...defaultValues, id: undefined } : {}),
          tmpid: `#${extraRows.length+1}`
        }
      ]
    })
  }

  removeParcel = (parcel) => {
    const id = parcel.id || parcel.tmpid
    if ((id + '').charAt(0) === '#') {
      this.setState(prevState => ({
        extraRows: prevState.extraRows.filter(p => p.tmpid !== id),
      }));
    } else {
      this.setState(prevState => ({
        deleteRows: [...prevState.deleteRows, id],
      }));
    }
  };

  getParcelInput = (parcel, field, options) => {
    //niezapisane pozycje, bez id
    let extraAttr = {}
    if(!parcel.id){
      extraAttr['data-tmpid'] = parcel.tmpid
    }
    if(typeof options !== 'undefined'){
      return(
        <select name={`${field}_${parcel.id}`}
                defaultValue={(parcel[field] && parcel[field][0]) || undefined}
                data-parcel="1"
                {...extraAttr}>
          {options.map(opt => (
            <option key={opt[0]} value={opt[0]}>{opt[1]}</option>
          ))}
        </select>
      )
    }
    return (
      <input type="number"
             step="0.01"
             name={`${field}_${parcel.id}`}
             defaultValue={parcel[field] && parcel[field].toFixed(2).replace(/[.,]00$/, "") || '0'}
             data-parcel="1"
             {...extraAttr}/>
    )
  }

  getParcelRow = (parcel, i) => {
    const { shipping_data } = this.props
    const { editMode } = this.state
    const sizes = shipping_data.sizes || []
    const types = shipping_data.types || []
  
    return (
      <tr key={parcel.id || parcel.tmpid} data-id={parcel.id || parcel.tmpid}>
        <td>{i + 1}{!parcel.id && '*'}</td>
        <td className="right">
          {(!editMode && parcel.weight && parcel.weight.toFixed(2).replace(/[.,]00$/, "")) || (
            this.getParcelInput(parcel, 'weight')
          )}
        </td>
        <td className="right">
          {(!editMode && (parcel.height || '')) || (
            this.getParcelInput(parcel, 'height')
          )}
        </td>
        <td className="right">
          {(!editMode && (parcel.length || '')) || (
            this.getParcelInput(parcel, 'length')
          )}
        </td>
        <td className="right">
          {(!editMode && (parcel.width || '')) || (
            this.getParcelInput(parcel, 'width')
          )}
        </td>
        <td className="right">
          {(!editMode && (parcel.size && parcel.size[1] || '')) ||
          (editMode && sizes.length > 0 && (
            this.getParcelInput(parcel, 'size', sizes)
          ))}
        </td>
        <td className="right">
          {(!editMode && (parcel.type_id && parcel.type_id[1] || '')) ||
          (editMode && types.length > 0 && (
            this.getParcelInput(parcel, 'type_id', types)
          ))}
        </td>
        <td className="right">
          {parcel.waybill || ''}
        </td>
        {editMode &&
          <td className="right">
            <button
              className="removeParcel"
              onClick={() => this.removeParcel(parcel)}
            >
            </button>
          </td>
        }
      </tr>
    )
  }

  renderField = (field, type, after) => {
    const { shipping_data } = this.props
    const { editMode } = this.state
    if(editMode){
      let inputProps = {
        name: field,
        defaultValue: shipping_data[field] || '',
        type: type==='float'?'number':'text'
      }
      if (type === 'float'){
        inputProps.step = '0.01'
      }
      return (
        <div>
          <input {...inputProps}/>
          {after || null}
        </div>
      )
    } else {
      return (
        <div>
          {shipping_data[field] || ''}
          {(shipping_data[field] || '') && (after || null)}
        </div>
      )
    }
  }

  carrierChange = (e) => {
    this.setState({
      selectedCarrier: parseInt(e.currentTarget.value)
    })
  }

  defaultCarrierId = () => {
    const { order } = this.props
    if(order.shipping_order_ids && order.shipping_order_ids.length > 0){
      return order.shipping_order_ids[order.shipping_order_ids.length - 1].carrier_id
    }
    return null
  }

  render() {
    const { shipping_data } = this.props
    const { editMode, newMode, extraRows, deleteRows, selectedCarrier } = this.state
    let parcels = []
    let parcel_locker
    if(shipping_data){
      if(!newMode){
        parcels = shipping_data.shipping_parcels_ids || []
      }
      parcel_locker = shipping_data.parcel_locker || false
      if(selectedCarrier){
        parcel_locker = shipping_data.carriers.find(c => {
          return c[0] === selectedCarrier
        })[2]
      }
    }
    return (
      <div id="root">
      <main>
        <div className="shipping_header">
          {newMode &&
            <>
              <button className="pull-left secondary" onClick={this.deselectShipping}>{intl.get('Anuluj')}</button>
              <button onClick={this.handleSubmit} className="pull-right" id="zatwierdz" >{intl.get('Zapisz')}</button>
            </>
          }
          {editMode && !newMode &&
            <>
              <button className="pull-left secondary" onClick={this.toggleEditMode}>{intl.get('Anuluj')}</button>
              <button onClick={this.handleSubmit} className="pull-right" id="zatwierdz" >{intl.get('Zapisz')}</button>
            </>
          }
          {!editMode && !newMode &&
            <>
              <button className="pull-left secondary" onClick={this.deselectShipping}>{intl.get('Wstecz')}</button>
              {!(shipping_data && shipping_data.waybill) &&
                <button onClick={this.toggleEditMode} className="pull-right" id="edytuj" >{intl.get('Edytuj')}</button>
              }
            </>
          }
        </div>
        {(shipping_data) &&
          <div className="shipping pakowanie_content">
            <div className="row">
              <div className="col-sm-6">
                <h4>{intl.get('Przewoźnik')}</h4>
                <div className="f_group">
                  <label>{intl.get('Metoda dostawy')}:</label>
                  {newMode &&
                    <select name="carrier_id" onChange={this.carrierChange} defaultValue={this.defaultCarrierId()}>
                      {shipping_data.carriers.map(carrier => (
                        <option key={carrier[0]} value={carrier[0]}>{carrier[1]}</option>
                      ))}
                    </select>
                  }
                  {!newMode && (
                    <div>{shipping_data.carrier_name}</div>
                  )}
                </div>
                <div className="f_group">
                  <label>{intl.get('List przewozowy')}:</label>
                  <div>{shipping_data.waybill}</div>
                </div>
                <div className="f_group">
                  <label>{intl.get('Data listu przewozowego')}:</label>
                  <div>{shipping_data.waybill_date}</div>
                </div>
                <div className="f_group">
                  <label>{intl.get('Link śledzenia')}:</label>
                  <div>
                    {shipping_data.tracking_url &&
                      <a target="_blank"
                         rel="noopener noreferrer"
                         href={shipping_data.tracking_url}>{shipping_data.tracking_url}</a>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <h4>{intl.get('Dane odbiorcy')}</h4>
                <div className="f_group">
                  <label>{intl.get('Nazwa')}:</label>
                  {this.renderField('receiver_name')}
                </div>
                <div className="f_group">
                  <label>{intl.get('Firma')}:</label>
                  {this.renderField('receiver_company')}
                </div>
                <div className="f_group">
                  <label>{intl.get('Ulica')}:</label>
                  {this.renderField('receiver_street')}
                </div>
                <div className="f_group">
                  <label>{intl.get('Miasto')}:</label>
                  {this.renderField('receiver_city')}
                </div>
                <div className="f_group">
                  <label>{intl.get('Kod pocztowy')}:</label>
                  {this.renderField('receiver_zip')}
                </div>
                <div className="f_group">
                  <label>{intl.get('Kraj')}:</label>
                  {editMode &&
                    <select name="receiver_country" defaultValue={shipping_data.countries.find(c => c[1] === shipping_data.receiver_country)[0]}>
                      {shipping_data.countries.map(country => (
                        <option key={country[0]} value={country[0]}>{country[1]}</option>
                      ))}
                    </select>
                  }
                  {!editMode && this.renderField('receiver_country')}
                </div>
                <div className="f_group">
                  <label>{intl.get('Email')}:</label>
                  {this.renderField('receiver_email')}
                </div>
                <div className="f_group">
                  <label>{intl.get('Telefon')}:</label>
                  {this.renderField('receiver_phone')}
                </div>
                <div className="f_group">
                  <label>{intl.get('Telefon komórkowy')}:</label>
                  {this.renderField('receiver_phone_mobile')}
                </div>
              </div>
              <div className="col-sm-6">
                <h4>{intl.get('Dane nadawcy')}</h4>
                <div className="f_group">
                  <label>{intl.get('Sklep')}:</label>
                  {newMode &&
                    <select name="shop_id" defaultValue={shipping_data.shop_id}>
                      {shipping_data.shops.map((shop) => (
                        <option key={shop[0]} value={shop[0]}>
                          {shop[1]}
                        </option>
                      ))}
                    </select>
                  }
                  {!newMode && (
                    <div>{shipping_data.shop}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <h4>{intl.get('Dane przesyłki')}</h4>
                { parcel_locker && (
                  <>
                    <div className="f_group">
                      <label>{intl.get('Punkt odbioru')}:</label>
                      {this.renderField('pickup_point_id')}
                    </div>
                    <div className="f_group">
                      <label>{intl.get('Ulica punktu odbioru')}:</label>
                      {this.renderField('pickup_point_street')}
                    </div>
                    <div className="f_group">
                      <label>{intl.get('Miasto punktu odbioru')}:</label>
                      {this.renderField('pickup_point_city')}
                    </div>
                    <div className="f_group">
                      <label>{intl.get('Kod pocztowy punktu odbioru')}:</label>
                      {this.renderField('pickup_point_zip')}
                    </div>
                  </>
                )}
                <div className="f_group">
                  <label>{intl.get('Pobranie')}:</label>
                  <input type="checkbox"
                         name="cod"
                         defaultChecked={!!shipping_data.cod}
                         disabled={!editMode}/>
                </div>
                <div className="f_group">
                  <label>{intl.get('Kwota pobrania')}:</label>
                  {this.renderField('cod_amount',
                                    'float',
                                    <span> {shipping_data.currency_id}</span>)
                  }
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <h4>{intl.get('Paczki')}</h4>
                <table className="parcels table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th className="right">{intl.get('Waga')}</th>
                      <th className="right">{intl.get('Wysokość')}</th>
                      <th className="right">{intl.get('Długość')}</th>
                      <th className="right">{intl.get('Szerokość')}</th>
                      <th className="right">{intl.get('Rozmiar')}</th>
                      <th className="right">{intl.get('Typ')}</th>
                      <th className="right">{intl.get('List przewozowy')}</th>
                      {editMode &&
                        <th></th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {!newMode && parcels.filter(p => !deleteRows.includes(p.id))
                                                       .map((parcel, i) => this.getParcelRow(parcel, i))}
                    {extraRows.map((parcel, i) => this.getParcelRow(parcel, i+parcels.length-deleteRows.length))}
                  </tbody>
                </table>
                {editMode &&
                  <button className="addRow" onClick={this.newParcelRow}>{intl.get('Dodaj pozycję')}</button>
                }
              </div>
            </div>
          </div>
        }
      </main>
      </div>
    )
  }
}

const Shipping = connect(mapStateToProps, mapDispatchToProps)(ShippingComponent)

Popup.registerPlugin('shippingOrder', function (shipping_id, parent) {
    this.create({
        title: intl.get('Przesyłka'),
        className: 'big_popup',
        content: <Shipping shipping_id={shipping_id} parent={parent}/>
    })
})
